<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!--自定义刷新组件-->
			<el-row :gutter="10"class="mb-1 flex align-center">
				<el-upload
				  class="upload-demo"
				  action="/manage/images/upload"
				  :show-file-list='false'
				  :on-success="uploadSuccess"
				  :before-upload="beforeUpload"
				  >
				  <el-button icon="el-icon-plus" size="mini" type="primary">新增</el-button>
				</el-upload>
				<div class="ml-auto">
					<el-tooltip class="item" effect="dark" content="刷新" placement="top">
					  <el-button size="mini" circle icon="el-icon-refresh" @click="getList" />
					</el-tooltip>
				</div>
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
				@cell-dblclick="doubleclick"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="缩略图" align="center">
				  <template slot-scope="scope">
					<img :src="scope.row.url" style="width: 30px;height: 30px;" @click="viewImage(scope.row)">
				  </template>
				</el-table-column>
				<el-table-column prop="name" label="图片名称" :show-overflow-tooltip="true" align="center">
				</el-table-column>
				<el-table-column
				  prop="url"
				  align="center"
				  :show-overflow-tooltip="true"
				  label="图片地址">
				</el-table-column>
				<el-table-column align="center" prop="ctime" label="时间">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'images',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
			}
		},
		mounted() {
			
		},
		methods:{
          uploadSuccess(e){
			  this.getList()
          },
          beforeUpload(file){
          	let fileArr = file.name.split('.')
          	let suffix = fileArr[fileArr.length - 1]
          	//只能导入图片文件
          	if (!/(jpg|jpeg|png|gif)/i.test(suffix)) {
          		this.$message.error('文件格式不正确')
          		return false
          	}
          	return true
          },
		  doubleclick(row, column, cell, event){
			  //双击的是那个单元格
			  if (column.property == "url") {
				const text = document.createElement('textarea'); // 创建节点
				text.setAttribute('readonly', 'readonly');
				text.value = row.url; // 赋值
				document.body.appendChild(text); // 插入节点
				text.setSelectionRange(0, text.value.length);
				text.select(); // 选中节点
				document.execCommand('copy'); // 执行浏览器复制方法
				if (document.body.removeChild(text)) {
				  this.$message({ type: 'success', message: '复制成功' })
				} else {
				  this.$message({ type: 'error', message: '复制失败' })
				}
			  }
			},
		}
	}
</script>

<style>
</style>